<template>
  <div class="header" ref="header">
    <div class="user-info">{{title}}</div>

    <div class="btns">
      <router-link class="btn order" v-if="hasBtn('order')" to="/waimai/orders">我的订单</router-link>
      <router-link class="btn home" v-if="hasBtn('home')" to="/waimai/index">外卖点餐</router-link>
    </div>
  </div>
</template>
<script>
import { defineComponent, createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {},
  props: {
    btns: {
      type: Array,
      default: () => ['order', 'left']
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup () {
    return {}
  },

  computed: {
    user: function () {
      return this.$store.state.user.userInfo
    }
  },

  mounted () {
    this.$nextTick(() => {
      const h = this.$refs.header.offsetHeight
      this.$emit('height', h)
    })
  },

  methods: {
    hasBtn (btn) {
      return this.btns.includes(btn)
    },
    openPsw () {

    },
    logout () {
      const _this = this
      _this.$confirm({
        content: '确定退出当前用户吗',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '退出',
        onOk () {
          _this.$store.dispatch('Logout').then(() => {
            _this.$message.success('已退出')

            _this.$router.push({ name: 'hall-login' })
          })
        }
      })
    }
  }
})
</script>

<style lang="less" scoped>
.header {
  background: #fff;
  padding: 0.3rem;
  position: relative;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .user-info {
    font-size: 0.3rem;
    align-items: center;
    padding: 0.1rem 0;
    color: #333;
    .user-name{
      display: inline-block;
      line-height: 0.3rem;
    }
  }

  .btns {
    display: flex;
    .btn {
      width: 1.5rem;
      height: 0.5rem;
      background-image: linear-gradient(to bottom, #8654fc, #7236f9);
      border-radius: 0.25rem;
      box-shadow: 0 0 0.24rem rgba(237, 125, 75, 0.5);
      text-align: center;
      line-height: 0.5rem;
      color: #fff;
      font-size: 0.24rem;
      &.home,&.left{
        background-image: linear-gradient(to bottom, #3f89f7, #368af9);
        margin-left: 0.2rem;
        box-shadow: 0 0 0.24rem rgba(75, 126, 237, 0.5);
      }
    }
  }
}
</style>

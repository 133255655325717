<template>
  <EHeader title="订单列表" :btns="['home']" />
  <div class="order-list">
    <a-spin :spinning="loading2" tip="Loading...">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="order" v-for="(v, k) in orderList" :key="k" @click="onDetail(v.id)">
            <div class="time">{{ v.createTime }} 下单</div>
            <div class="info">
              共{{ v.orderItems.length }}件菜品

              <div class="price">
                订单金额： <span>¥</span> <strong>{{ v.payAmount }}</strong>
              </div>
            </div>

            <div class="actions">
              <div class="status">
                <a-tag v-if="v.orderStatus === 2" color="#ffa39e">待支付</a-tag>
                <a-tag v-if="v.orderStatus === 5" color="#f5222d">退订</a-tag>
                <a-tag v-if="v.orderStatus === 7" color="#52c41a">结算完成</a-tag>
                <a-tag v-if="v.orderStatus === 8" color="#fa8c16">退款</a-tag>
                <a-tag v-if="v.orderStatus === 10" color="#ccc">作废</a-tag>
              </div>
              <div v-if="v.orderStatus === 2">
                <a-spin :spinning="loading3">
                  <div class="btn pay" @click.stop="onPay(v.id)">立即支付</div>
                </a-spin>
              </div>
              <div v-if="v.orderStatus === 7">
                <a-spin :spinning="loading4">
                  <div class="btn again">再来一单</div>
                </a-spin>
              </div>
              <div v-if="v.orderStatus === 10">
                <a-spin :spinning="loading5">
                  <div class="btn del">删除</div>
                </a-spin>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </a-spin>
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Tab, Tabs, List, PullRefresh } from 'vant'
import 'vant/es/list/style'
import 'vant/es/pull-refresh/style'
import 'vant/es/tab/style'
import 'vant/es/tabs/style'
import EHeader from '@/components/mobile/Header2'
import { OrderClass } from '@/apis/order'
import storage from 'store'

import { WxGZHCLass } from '@/apis/wxgzh'

import defaultConfig from '@/config/default.config'
import { isIos, wechatConfig } from '@/utils'

const order = new OrderClass()
const wxgzh = new WxGZHCLass()
export default defineComponent({
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    EHeader
  },

  setup () {
    const loading = ref(false)
    const loading2 = ref(false)
    const loading3 = ref(false)
    const loading4 = ref(false)
    const loading5 = ref(false)

    const orderList = ref([])
    const refreshing = ref(false)
    const finished = ref(false)
    const pagenation = ref({
      total: 0,
      page: 1
    })
    const queryParams = ref({
      Limit: 20
    })

    return {
      loading,
      loading2,
      loading3,
      loading4,
      loading5,
      orderList,
      refreshing,
      finished,
      pagenation,
      queryParams
    }
  },

  created () {
    this.getOrders(1)

    window.onbeforeunload = (e) => {
      const url = defaultConfig.host + this.$route.path
      storage.set('wxConfigURL', url)
    }

    if (!isIos()) {
      wechatConfig()
    } else {
      const url = storage.get('wxConfigURL')
      if (url.indexOf('login') === -1 && url.indexOf('regist') === -1) {
        wechatConfig(url)
      }
    }
  },

  methods: {
    getOrders (page) {
      this.loading = true
      this.loading2 = true
      if (page === 1) {
        this.orderList = []
      }
      this.pagenation.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit
      q.StoreId = storage.get('SCAN_STORE_ID')

      order.personalGets(q).then((resp) => {
        this.orderList.push(...resp.data)

        this.pagenation.total = resp.total
        this.loading = false
        this.loading2 = false
        this.refreshing = false

        if (page >= resp.total) {
          this.finished = true
        } else {
          this.finished = false
        }
      })
    },
    onRefresh () {
      this.refreshing = true
      this.getOrders(1)
    },

    onLoad () {
      if (
        this.pagenation.page * this.queryParams.Limit >=
          this.pagenation.total ||
        this.pagenation.total <= this.queryParams.Limit
      ) {
        this.finished = true
      } else {
        this.getOrders(++this.pagenation.page)
      }
    },

    onDetail (orderId) {
      this.$router.push({
        name: 'waimai-order',
        params: { id: orderId }
      })
    },

    onPay (orderId) {
      const _this = this
      this.loading3 = true
      this.$confirm({
        title: '提示',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '确认支付？'
        ),
        onCancel () {
          _this.loading3 = false
        },
        onOk () {
          wxgzh.pay(orderId).then(resp => {
            // eslint-disable-next-line no-undef
            wx.chooseWXPay({
              timestamp: resp.timeStamp,
              nonceStr: resp.nonceStr,
              package: resp.package,
              signType: resp.signType,
              paySign: resp.paySign,
              success: function (res) {
                _this.$router.push({
                  name: 'waimai-order',
                  params: { id: orderId }
                })
              }
            })
          })
        }
      })
    }
  }
})
</script>

<style lang="less" scoped>
.order-list {
  padding: 0.2rem;
  .order {
    position: relative;
    margin-bottom: 0.15rem;
    border-radius: 0.15rem;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 0.1rem 0;
    .time {
      font-size: 0.26rem;
      line-height: 0.4rem;
      color: #666;
      padding: 0 0.2rem;
    }
    .info {
      font-size: 0.32rem;
      line-height: 0.6rem;
      color: #333;
      padding: 0 0.2rem;
      display: flex;
      justify-content: space-between;
      .price {
        font-size: 0.26rem;
        color: #666;
        span {
          font-size: 0.2rem;
        }
        strong {
          font-size: 0.34rem;
          color: #f50;
        }
      }
    }
    .btn {
      width: 1.8rem;
      height: 0.5rem;
      color: #333;
      font-size: 0.2rem;
      line-height: 0.5rem;
      text-align: center;
      border-radius: 3px;
      border: 1px solid transparent;
      &.pay {
        background: #7236f9;
        color: #fff;
        border-color: #7236f9;
      }
      &.again {
        border-color: #333;
      }
      &.del {
        border-color: #666;
        color: #666;
        background: #e1e1e1;
      }
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eee;
      padding: 0.1rem 0.2rem 0;
      position: relative;
      height: 0.6rem;
      .status {
        position: absolute;
        top: 0;
        left: 0.2rem;
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }
}
</style>
